@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Six+Caps&display=swap');

body {
  position: relative;
}

#app {
  min-height: 100dvh;
}

@supports (-webkit-touch-callout: none) {
  #app {
    height: -webkit-fill-available;
  }
}

.locked {
  overflow: hidden;
}

.h-open-notify,
#fc_frame.h-open-notify,
#fc_frame.fc-widget-small.h-open-notify {
  opacity: 0 !important;
}

.disintegration-container {
  position: absolute;
  pointer-events: none;
}

.disintegration-container > canvas {
  position: absolute;
  left: 0;
  top: 0;
  transition:
    transform 1s ease-out,
    opacity 1s ease-out;
  opacity: 1;
  transform: rotate(0deg) translate(0px, 0px) rotate(0deg);
}

@keyframes debug-pulse {
  0% {
    filter: none;
  }
  95% {
    filter: none;
  }
  95% {
    filter: drop-shadow(0 1px 0 rgba(255, 0, 0, 1));
  }
  100% {
    filter: drop-shadow(0 1px 0 rgba(255, 0, 0, 1));
  }
}

@keyframes cutCardTop {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  30% {
    transform: translate(20px, -100px) rotate(5deg);
  }
  100% {
    transform: translate(0px, 300%) rotate(52deg);
  }
}

@keyframes cutCardBottom {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  30% {
    transform: translate(20px, 100px) rotate(-5deg);
  }
  100% {
    transform: translate(0px, 200%) rotate(-17deg);
  }
}
